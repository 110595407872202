import React, {useState} from 'react';
import Modal, {ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../../../components/bootstrap/Modal";
import Icon from "../../../../../components/icon/Icon";
import Button from "../../../../../components/bootstrap/Button";
import FormGroup from "../../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../../components/bootstrap/forms/Input";
import {apiExternal} from "../../../../../services/api";
import showNotification from "../../../../../components/extras/showNotification";
import Spinner from "../../../../../components/bootstrap/Spinner";

export function ModalPhone({ isOpen, setIsOpen }) {
    const [loading, setLoading] = useState(false);
    const [phone, setPhone] = useState('');

    const confirm = () => {
        if (phone == '') {
            return showNotification('Atenção!', 'Informe o telefone antes de continuar.', 'warning', true);
        }

        setLoading(true);
        apiExternal.post('externalSchedule/user/updatePhone', {'phone': phone}).then(resp => {
            setLoading(false);
            if (resp.data.error) {
                return showNotification('Atenção!', resp.data.message, 'warning', true);
            }
            showNotification('Sucesso!', resp.data.message, 'success', true);
            return setIsOpen();
        })
    }

    return (
        <Modal
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            isStaticBackdrop
            isScrollable
            data-tour='search-modal'>
            <ModalHeader>
                <ModalTitle id='modalConfirm' className='text-warning text-center'>
                    <Icon icon='Error' size='2x' color='warning' />
                    Atenção!
                </ModalTitle>
            </ModalHeader>

            <ModalBody className='text-center' style={{'fontSize': '22px'}}>
                <div className='row g-4'>
                    <div className='col-12'>
                        <FormGroup required id='phoneCustomer' label='Telefone' isFloating>
                            <Input
                                mask="(99) 99999-9999"
                                placeholder='Telefone'
                                autoComplete='phoneCustomer'
                                onChange={(e) => setPhone(e.target.value)}
                                value={phone}
                            />
                        </FormGroup>
                    </div>
                </div>
            </ModalBody>

            <ModalFooter>
                <Button
                    icon='Close'
                    color='danger'
                    className='border-0'
                    onClick={setIsOpen}>
                    Cancelar
                </Button>
                <Button
                    color='success'
                    icon='Check'
                    onClick={confirm}>
                    {
                        loading && (
                            <Spinner isSmall inButton />
                        )
                    }
                    {loading ? 'Aguarde...' : 'Salvar' }
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default ModalPhone;