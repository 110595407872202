import React, {useState} from 'react';
import FormSignin from "./Components/FormSignin";
import Card, {
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle,
} from '../../../../components/bootstrap/Card';
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Label from '../../../../components/bootstrap/forms/Label';
import {format} from "date-fns";
import Button from "../../../../components/bootstrap/Button";
import Spinner from "../../../../components/bootstrap/Spinner";
import {apiExternal} from "../../../../services/api";
import showNotification from "../../../../components/extras/showNotification";
import {isAuthenticatedExternal, removeTokenExternal} from "../../../../services/auth";
import Input from "../../../../components/bootstrap/forms/Input";
import ModalPhone from "./Components/ModalPhone";

export function BookingForm({ dateAndTime, services, timeEstimated, barber, valueTotal, companySelected, setStep }) {
    const [loading, setLoading] = useState(false);
    const [isFormSignin, setIsFormSignin] = useState(false);
    const [isOpenPhone, setIsOpenPhone] = useState(false);
    const [userAuth, setUserAuth] = useState({
        isAuth: false,
        user: null,
        userNotRecorded: {name: '', phone: ''}
    });

    const togglePhone = () => setIsOpenPhone((prev) => !prev);

    const toSchedule = () => {
        let payload = {
            'recurrent': "0",
            'time_aggregate': 0,
            'time_estimated': timeEstimated,
            'value': valueTotal,
            'end': '',
            'start': dateAndTime.selectedTimeSlot,
            'date': format(dateAndTime.selectedDate, 'yyyy-MM-dd'),
            'barber_id': barber.id,
            'companyId': companySelected.id,
            'services': services.map(service => service.id),
            'customer': null,
            'name_customer': userAuth.userNotRecorded?.name,
            'phone_customer': userAuth.userNotRecorded?.phone
        };

        setLoading(true)
        apiExternal.post(`externalSchedule/toSchedule`, payload).then(resp => {
            setLoading(false)
            if (resp.data.error) {
                showNotification('Atenção!', resp.data.message, 'warning', true);

                if (resp.data.errorPhone && isAuthenticatedExternal()) {
                    togglePhone();
                } else {
                    setStep(3);
                }
            } else {
                removeTokenExternal();
                setStep(5);
                showNotification('Sucesso!', resp.data.message, 'success', true);
            }
        });
    }

    const resumeScheduling = () => (
        <div className='row'>
            <div className='col-12'>
                <FormGroup>
                    <Label title='Resumo'>
                        <p>Olá, { userAuth.user?.full_name ? userAuth.user.full_name : (userAuth.userNotRecorded.name + ' - ' + userAuth.userNotRecorded.phone) }</p>
                        <p>Resumo do seu agendamento em { format(dateAndTime.selectedDate, 'dd/MM/yyyy') } as { dateAndTime.selectedTimeSlot } com o { barber.full_name }</p>
                        <p>Serviços: </p>
                        {
                            services.map((service, key) => (
                                <p style={{marginLeft: '18px'}}>{(key + 1)}: { service.surname }</p>
                            ))
                        }
                        <p>Tempo estimado: { timeEstimated }</p>
                        <p>Total R$: { valueTotal }</p>
                    </Label>
                </FormGroup>
            </div>

            <div className='col-12'>
                <Button color='green' className='w-100 py-3' onClick={ toSchedule }>
                    {
                        loading && (
                            <Spinner isSmall inButton />
                        )
                    }
                    {loading ? 'Aguarde...' : 'Confirmar Agendamento' }
                </Button>
            </div>
        </div>
    )

    const formUserNotRecorded = () => (
        <div className='row g-4'>
            <div className='col-12'>
                <FormGroup required id='nameCustomer' isFloating label='Nome Completo'>
                    <Input
                        autoComplete='nameCustomer'
                        placeholder='Nome Completo'
                        name='nameCustomer'
                        onChange={(e) => setUserAuth({ ...userAuth, userNotRecorded: { ...userAuth.userNotRecorded, name: e.target.value}})}
                        value={userAuth.userNotRecorded.name}
                    />
                </FormGroup>
            </div>

            {
                (companySelected && companySelected.configuration.required_phone === '1') && (
                    <div className='col-12'>
                        <FormGroup required id='phoneCustomer' label='Telefone' isFloating>
                            <Input
                                mask="(99) 99999-9999"
                                placeholder='Telefone'
                                autoComplete='phoneCustomer'
                                onChange={(e) => setUserAuth({ ...userAuth, userNotRecorded: { ...userAuth.userNotRecorded, phone: e.target.value}})}
                                value={userAuth.userNotRecorded.phone}
                            />
                        </FormGroup>
                    </div>
                )
            }

            <div className='col-12'>
                <Button color='green' className='w-100 py-3' disabled={loading} onClick={ ()=> {
                    if (!userAuth.userNotRecorded.name) {
                        return showNotification('Atenção!', 'Digite o nome antes de agendar.', 'warning', true);
                    }

                    if ((companySelected && companySelected.configuration.required_phone === '1' && !userAuth.userNotRecorded.phone)) {
                        return showNotification('Atenção!', 'Digite o telefone antes de agendar.', 'warning', true);
                    }

                    return setUserAuth({ ...userAuth, isAuth: true })
                } }>
                    {
                        loading && (
                            <Spinner isSmall inButton />
                        )
                    }
                    {loading ? 'Aguarde...' : 'Confirmar' }
                </Button>

                <Button
                    color='primary'
                    className='w-100 py-3'
                    style={{ marginTop: 10 }}
                    onClick={() => {
                        setIsFormSignin(true);
                    }}
                >
                    Já tenho conta
                </Button>
            </div>
        </div>
    )

    return (
          <Card stretch={true} shadow='lg'>
              <CardHeader>
                  <CardLabel icon='PeopleAlt' iconColor='primary'>
                      <CardTitle>
                          Identificação
                      </CardTitle>
                  </CardLabel>
              </CardHeader>
              <CardBody className='table-responsive' isScrollable>
                  {
                      (companySelected.configuration.required_record_external_schedule == '1' && !userAuth.isAuth) && (
                          <FormSignin
                              funcConcluded={ setUserAuth }
                              companySelected={companySelected}
                          />
                      )
                  }

                  {
                      (companySelected.configuration.required_record_external_schedule == '0' && !userAuth.isAuth) && (
                            isFormSignin ? (
                                <FormSignin
                                    toggleForm={() => setIsFormSignin(false)}
                                    funcConcluded={ setUserAuth }
                                    companySelected={companySelected}
                                />
                            ) : (
                                formUserNotRecorded()
                            )
                      )
                  }

                  {
                      userAuth.isAuth && (
                          resumeScheduling()
                      )
                  }

                  <ModalPhone
                      isOpen={isOpenPhone}
                      setIsOpen={togglePhone}
                  />
              </CardBody>
          </Card>
    );
}

export default BookingForm;