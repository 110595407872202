const BARBERS = [
  {
    id: 1,
    name: 'João Silva',
    specialty: 'Especialista em Cortes Modernos',
    experience: '8 anos de experiência',
    image: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=800&auto=format&fit=crop&q=60'
  },
  {
    id: 2,
    name: 'Pedro Santos',
    specialty: 'Mestre em Barbas',
    experience: '12 anos de experiência',
    image: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=800&auto=format&fit=crop&q=60'
  },
  {
    id: 3,
    name: 'Carlos Oliveira',
    specialty: 'Especialista em Degradê',
    experience: '6 anos de experiência',
    image: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=800&auto=format&fit=crop&q=60'
  }
];

export function Barbers({ onBarberSelect, barbers }) {
  const handleBarberSelect = (barber) => {
    onBarberSelect(barber);
  };

  return (
    <div className="barbers-container">
      <h2 className="barbers-title">Escolha seu Barbeiro</h2>
      
      <div className="barbers-grid">
        {barbers.map((barber) => (
          <div
            key={barber.id}
            onClick={() => handleBarberSelect(barber)}
            className={`barber-card`}
          >
            <div className="barber-image-container">
              <img
                src={process.env.REACT_APP_HOST_API + (barber.file_system !== null ? barber.file_system.path : '/images/noImage.png')}
                alt={barber.full_name}
                className="barber-image"
              />
            </div>
            <div className="barber-content">
              <h3 className="barber-name">{barber.full_name}</h3>
              {/*<p className="barber-specialty">{barber.specialty}</p>*/}
              {/*<p className="barber-experience">{barber.experience}</p>*/}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
