import React, {useEffect, useState} from 'react';
import {useFormik} from "formik";
import FormGroup from "../../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../../components/bootstrap/forms/Input";
import Button from "../../../../../components/bootstrap/Button";
import Spinner from "../../../../../components/bootstrap/Spinner";
import showNotification from "../../../../../components/extras/showNotification";
import {apiExternal} from "../../../../../services/api";
import {setTokenExternal} from "../../../../../services/auth";

export function FormSignin({ companySelected, funcConcluded, toggleForm }) {
    const requiredFone = (companySelected !== null && companySelected.configuration.required_phone === '1');
    const [loading, setLoading] = useState(false);
    const [isSignup, setIsSignup] = useState(false);
    const toggleIsSignup = () => setIsSignup((prev) => !prev);

    const formik = useFormik({
        initialValues: {
            company_id: null,
            username: '',
            full_name: '',
            new_password: '',
            confirm_password: '',
            password: '',
        }
    });
    const resetPassword = () => {
        setLoading(true);
        return apiExternal.post('/auth/sendResetPassword', { ...formik.values, email: formik.values.username }).then(res => {
            setLoading(false);

            if (res.data.error) {
                showNotification('Atenção!', res.data.message, 'warning', true);
            } else {
                showNotification('Sucesso!', res.data.message, 'success', true);
            }
        })
    }
    const login = () => {
        if (!formik.values.username || !formik.values.password) {
            return showNotification('Atenção!', 'Informe o Email ou Telefone e Senha antes de continuar.', 'warning', true);
        }

        setLoading(true)
        apiExternal.post(`externalSchedule/login`, formik.values).then(resp => {
            setLoading(false)
            if (resp.data.error) {
                showNotification('Atenção!', resp.data.message, 'warning', true);
            } else {
                showNotification('Sucesso!', resp.data.message, 'success', true);
                setTokenExternal(resp.data.token);
                funcConcluded({isAuth: true, user: resp.data.user});
            }
        });
    }
    const create = () => {
        setLoading(true)
        apiExternal.post(`externalSchedule/create`, formik.values).then(resp => {
            setLoading(false)
            if (resp.data.error) {
                showNotification('Atenção!', resp.data.message, 'warning', true);
            } else {
                showNotification('Sucesso!', resp.data.message, 'success', true);
                setTokenExternal(resp.data.token);
                funcConcluded({isAuth: true, user: resp.data.user});
            }
        });
    }

    useEffect(() => {
        if (companySelected != null) {
            formik.setFieldValue('company_id', companySelected.id)
        }
    }, [companySelected]);

    return (
        <>
            {
                isSignup ? (
                    <div className='row g-4'>
                        <div className='col-md-6'>
                            <FormGroup required id='full_name' label='Nome completo' isFloating>
                                <Input
                                    placeholder='Nome completo'
                                    autoComplete='full_name'
                                    onChange={formik.handleChange}
                                    value={formik.values.full_name}
                                />
                            </FormGroup>
                        </div>

                        <div className='col-md-6'>
                            <FormGroup required={requiredFone} id='phone' label='Telefone' isFloating>
                                <Input
                                    mask="(99) 99999-9999"
                                    placeholder='Telefone'
                                    autoComplete='phone'
                                    onChange={formik.handleChange}
                                    value={formik.values.phone}
                                />
                            </FormGroup>
                        </div>

                        <div className='col-md-6'>
                            <FormGroup required id='email' label='E-mail' isFloating>
                                <Input
                                    placeholder='E-mail'
                                    autoComplete='email'
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                />
                            </FormGroup>
                        </div>

                        <div className='col-md-6'>
                            <FormGroup required id='new_password' label='Senha' isFloating>
                                <Input
                                    type='password'
                                    placeholder='Senha'
                                    onChange={formik.handleChange}
                                    value={formik.values.new_password}
                                />
                            </FormGroup>
                        </div>

                        <div className='col-md-6'>
                            <FormGroup required id='confirm_password' label='Confirme a Senha' isFloating>
                                <Input
                                    type='password'
                                    placeholder='Confirme a Senha'
                                    autoComplete='family-name'
                                    onChange={formik.handleChange}
                                    value={formik.values.confirm_password}
                                />
                            </FormGroup>
                        </div>

                        <div className='col-12'>
                            <Button color='green' className='w-100 py-3' disabled={loading}
                                    onClick={ create }>
                                {
                                    loading && (
                                        <Spinner isSmall inButton />
                                    )
                                }
                                {loading ? 'Aguarde...' : 'Criar Conta' }
                            </Button>

                            <Button
                                disabled={loading}
                                color='primary'
                                className='w-100 py-3'
                                style={{ marginTop: 10 }}
                                onClick={toggleIsSignup}>
                                {
                                    loading && (
                                        <Spinner isSmall inButton />
                                    )
                                }
                                {loading ? 'Aguarde...' : 'Já tenho conta' }
                            </Button>

                            {
                                toggleForm && (
                                    <Button
                                        color='info'
                                        className='w-100 py-3'
                                        style={{ marginTop: 10 }}
                                        onClick={toggleForm}
                                    >
                                        Agendar sem conta
                                    </Button>
                                )
                            }
                        </div>
                    </div>
                ) : (
                    <div className='row g-4'>
                        <div className='col-12'>
                            <FormGroup id='login-username' isFloating label='Telefone ou E-mail'>
                                <Input
                                    autoComplete='username'
                                    placeholder='Telefone ou E-mail'
                                    name='username'
                                    onChange={formik.handleChange}
                                    value={formik.values.username}
                                />
                            </FormGroup>
                        </div>

                        <div className='col-12'>
                            <FormGroup id='login-password' isFloating label='Senha'>
                                <Input
                                    type='password'
                                    autoComplete='password'
                                    onChange={formik.handleChange}
                                    value={formik.values.password}
                                    name='password'
                                />
                            </FormGroup>
                        </div>

                        <div className="col-xs-6 text-rigth" style={{marginTop: 8, textAlign: 'end'}}>
                            <a onClick={resetPassword}>Esqueceu sua senha?</a>
                        </div>

                        <div className='col-12'>
                            <Button color='green' className='w-100 py-3' disabled={loading} onClick={ login }>
                                {
                                    loading && (
                                        <Spinner isSmall inButton />
                                    )
                                }
                                {loading ? 'Aguarde...' : 'Entrar' }
                            </Button>

                            <Button
                                color='primary'
                                className='w-100 py-3'
                                style={{ marginTop: 10 }}
                                onClick={toggleIsSignup}
                            >
                                Cadastrar-se
                            </Button>

                            {
                                toggleForm && (
                                    <Button
                                        color='info'
                                        className='w-100 py-3'
                                        style={{ marginTop: 10 }}
                                        onClick={toggleForm}
                                    >
                                        Agendar sem conta
                                    </Button>
                                )
                            }
                        </div>
                    </div>
                )
            }
        </>
    );
}

export default FormSignin;