import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import BookingForm from './components/BookingForm/BookingForm';
import Calendar from './components/Calendar/Calendar';
import { Services } from './components/Services/Services';
import { Barbers } from './components/Barbers/Barbers';
import '../../styles/externalStyles.scss';
import api from "../../services/api";
import showNotification from "../../components/extras/showNotification";
import {removeTokenExternal} from "../../services/auth";
import Confirmation from "./components/Confirmation/Confirmation";

export const ExternalSchedule = () => {
  const { company } = useParams();
  const [ companySelected, setCompanySelected ] = useState(null);
  const [ services, setServices ] = useState([]);
  const [ barbers, setBarbers ] = useState([]);
  const [step, setStep] = useState(1);
  const [booking, setBooking] = useState({
    barber: null,
    services: [],
    date: null,
    name: '',
    phone: '',
    timeEstimated: 0,
    valueTotal: 0,
    barberId: null,
    dateAndTime: null
  });

  const handleBarberSelect = (barber) => {
    setBooking((prev) => ({ ...prev, barber }));
    setStep(3);
  };

  const handleServicesSelect = (services, totalPrice, totalDuration) => {
    setBooking((prev) => ({ ...prev, services, valueTotal: totalPrice, timeEstimated: totalDuration }));

    api.post(`externalSchedule/getBarbersWithService`, {'services': services, 'companyId': companySelected.id})
        .then(resp => {
        if (resp.data.length > 0) {
          setBarbers(resp.data);
          setStep(2);
        }
    });
  };

  const handleDateSelect = (date) => {
    setBooking((prev) => ({ ...prev, date }));
  };

  const handleAgendar = (dateAndTime) => {
    setBooking((prev) => ({ ...prev, dateAndTime: dateAndTime }));
    setStep(4);
  };
  const getCompany = () => {
    api.post(`externalSchedule/getCompany`, {'company': company}).then(resp => {
      if (resp.data.company === null) {
        showNotification('Atenção!', 'Nenhuma barbearia encontrada, entre em contato com seu barbeiro!', 'warning', true);
        setTimeout(function () {
          return window.location.href = 'https://eibarber.com.br/'
        }, 2000)
      }
      setCompanySelected(resp.data.company);
      setServices(resp.data.services);
      removeTokenExternal();
    });
  }

  useEffect(() => getCompany() , []);

  return (
    <div className="external-schedule-container-specific">
      <h1 className="external-schedule-title">Agendamento - { companySelected === null ? ' ' : companySelected.fantasy_name }</h1>

      <div className="step-indicator-specific">
        <div className={`step-circle-specific ${step >= 1 ? 'active' : ''}`}>1</div>
        <div className={`step-line-specific ${step >= 2 ? 'active' : ''}`} />
        <div className={`step-circle-specific ${step >= 2 ? 'active' : ''}`}>2</div>
        <div className={`step-line-specific ${step >= 3 ? 'active' : ''}`} />
        <div className={`step-circle-specific ${step >= 3 ? 'active' : ''}`}>3</div>
        <div className={`step-line-specific ${step >= 4 ? 'active' : ''}`} />
        <div className={`step-circle-specific ${step >= 4 ? 'active' : ''}`}>4</div>
      </div>

      {step === 1 && <Services onServicesSelect={handleServicesSelect} services={services} />}
      {step === 2 && <Barbers onBarberSelect={handleBarberSelect} barbers={barbers} />}
      {step === 3 && (
        <Calendar
          booking={booking}
          onDateSelect={handleDateSelect}
          companySelected={companySelected}
          handleContinue={handleAgendar}
        />
      )}
      {step === 4 && (
          <BookingForm
              setStep={setStep}
              dateAndTime={booking.dateAndTime}
              services={booking.services}
              timeEstimated={booking.timeEstimated}
              valueTotal={booking.valueTotal}
              barber={booking.barber}
              companySelected={companySelected}
          />
      )}
      {step === 5 && (
          <Confirmation/>
      )}
    </div>
  );
};

export default ExternalSchedule;
