import React, { useState } from 'react';
import { formatForCalc, formatMoney } from '../../../../helper/functions'
import showNotification from "../../../../components/extras/showNotification";
import Spinner from "../../../../components/bootstrap/Spinner";
import Button from "../../../../components/bootstrap/Button";

export function Services({ onServicesSelect, services }) {
  const [selectedServices, setSelectedServices] = useState([]);
  const toggleService = (serviceId) => {
    setSelectedServices(prev => {
      const isSelected = prev.includes(serviceId);
      if (isSelected) {
        return prev.filter(id => id !== serviceId);
      } else {
        return [...prev, serviceId];
      }
    });
  };

  const handleContinue = () => {
    if (selectedServices.length > 0) {
      const selected = services.filter(service => selectedServices.includes(service.id));
      onServicesSelect(selected, totalPrice, totalDuration);
    }
  };

  const totalPrice = services
    .filter(service => selectedServices.includes(service.id))
    .reduce((sum, service) => sum + formatForCalc(service.sale_value), 0);

  const totalDuration = services
    .filter(service => selectedServices.includes(service.id))
    .reduce((sum, service) => sum + service.estimated_time, 0);

  return (
    <div className="container">
      <h2 className="text-center mb-6">Escolha os Serviços</h2>
      
      <div className="services-grid">
        {services.map((service) => (
          <div
            key={service.id}
            onClick={() => toggleService(service.id)}
            className={`service-card ${selectedServices.includes(service.id) ? 'selected' : ''}`}
          >
            <div className="service-image-container">
              <img
                src={process.env.REACT_APP_HOST_API + (service.file_system !== null ? service.file_system.path : '/images/noImage.png')}
                alt={service.surname}
                className="service-image"
              />
              {selectedServices.includes(service.id) && (
                <div className="service-check">✓</div>
              )}
            </div>
            <div className="service-content">
              <h3 className="service-name">{service.name}</h3>
              <p className="service-description">{service.description}</p>
              <div className="service-footer">
                <span className="service-price">
                  R$ {service.sale_value}
                </span>
                <span className="service-duration">
                  {service.estimated_time} min
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>

      {selectedServices.length > 0 && (
        <div className="services-summary">
          <div className="summary-container">
            <div className="summary-info">
              
                <p className="summary-total">
                    <>Total:</> R$ {formatMoney(totalPrice)}
                </p>
              <p className="summary-duration">
                <>Duração estimada:</> {totalDuration} min
              </p>
            </div>

            <Button color='green' className='summary-continue-btn' onClick={ handleContinue }>
              Continuar
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Services;
