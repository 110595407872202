import React, {useEffect, useState} from 'react';
import Button from "../../../../components/bootstrap/Button";

const Confirmation = () => {
    const [ isAndroid, setIsAndroid ] = useState(false);

    useEffect(() => {
        const { userAgent } = window.navigator;
        const { platform } = window.navigator;
        const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

        if (iosPlatforms.indexOf(platform) !== -1) {
            setIsAndroid(false)
        } else if (/Android/.test(userAgent)) {
            setIsAndroid(true)
        }
    }, []);

  return (
    <>
        <div className='text-center text-success display-6 fw-bold pt-3 pb-5'>Que ótimo! Seu agendamento foi realizado com sucesso.</div>
        <div className='text-center display-12 fw-bold pt-3 pb-5'>
            <code style={{ color: '#72D8F8' }}>EiBarber</code>, seu próximo corte de cabelo está aqui!
        </div>

        <div className='text-center display-12 fw-bold pt-3 pb-5'>
            Conheça nosso aplicativo, realize o download e obtenha novos benefícios.
        </div>

        <div className='row'>
            <div className='text-center col-6'>
                <img src={process.env.REACT_APP_HOST_API + '/images/Tela1.jpeg'}
                     alt='' className='img-fluid object-fit-contain p-3'
                />
            </div>

            <div className='text-center col-6'>
                <img src={process.env.REACT_APP_HOST_API + '/images/Tela2.jpeg'}
                     alt='' className='img-fluid object-fit-contain p-3'
                />
            </div>
        </div>

        <div className='text-center display-12 fw-bold pt-3 pb-5'>
            Disponível para Android e IOS. Baixe agora!
        </div>

        <div className='col-12 text-center'>
            <Button
                icon='AppSettingsAlt'
                color='light'
                type='button'
                tag='a'
                href={
                    isAndroid ?
                        'https://play.google.com/store/apps/details?id=com.linksystem.eibarber' :
                        'https://apps.apple.com/us/app/ei-barber/id1664266768'
                }
            >
                Quero Baixar
            </Button>
        </div>

        <div className='text-center display-12 fw-bold pt-3 pb-5'>
            Problemas com o botão? <a href='https://api.eibarber.com.br/downloadEiBarber'>Clique aqui</a>
        </div>
    </>
  );
};

export default Confirmation;